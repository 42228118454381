import React, { useEffect, useState } from "react";
import type { GatsbyBrowser } from "gatsby";
import { Box, Container, Typography } from "@mui/material";
import { weddingName1, weddingName2 } from "./src/utils";
import love from "./src/images/hearts.gif";
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  const devMode = false;
  const [phase, setPhase] = useState<string>("");
  const shouldReduce =
    weddingName1.length + weddingName2.length > 8 && window.innerWidth < 600;

  useEffect(() => {
    setTimeout(() => {
      setPhase("begin");
    }, 200);

    setTimeout(() => {
      setPhase("begin2");
    }, 600);

    setTimeout(() => {
      setPhase("begin2heart");
    }, 1000);

    setTimeout(() => {
      setPhase("begin2");
    }, 2000);

    setTimeout(() => {
      setPhase("endBegin");
      setTimeout(() => {
        setPhase("endBegin2");
      }, 50);
    }, 2500);

    setTimeout(() => {
      setPhase("end");
    }, 2800);
  }, []);

  if (devMode) return <>{element}</>;
  return (
    <>
      {element}

      <Box
        sx={{
          top: phase === "end" ? "-100vh" : "0",
          position: "fixed",
          zIndex: 9999,
          left: 0,
          width: "100%",
          height: "100vh",
          backgroundColor: "#161616",
          transition: "1s",
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "100%",
            color: "white",
            fontFamily: "Poppins",
            textShadow:
              "0 0 5px #ff00de, 0 0 10px #ff00de, 0 0 15px #ff00de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de, 0 0 55px #ff00de, 0 0 75px #ff00de",
          }}
        >
          <Typography
            variant="h3"
            className={`logo notranslate ${
              phase.includes("begin") ? "active" : "fade"
            }`}
            sx={{ textAlign: "right" }}
          >
            {shouldReduce ? weddingName1[0] : weddingName1}
          </Typography>
          <Box sx={{ width: "80px" }}></Box>
          <Typography
            variant="h3"
            className={`logo notranslate ${
              phase.includes("begin2") || phase === "endBegin"
                ? "active"
                : "fade"
            }`}
            sx={{}}
          >
            {shouldReduce ? weddingName2[0] : weddingName2}
          </Typography>
        </Container>
        <img
          src={love}
          alt="heart"
          className={`logo ${phase === "begin2heart" ? "active" : "fade"}`}
          style={{
            position: "absolute",
            margin: "auto",
            left: "-9970px",
            right: "-9999px",
            top: "0",
            bottom: "10%",
            width: "500px",
          }}
        />
      </Box>
    </>
  );
};

// export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({
//   location,
//   prevLocation,
// }) => {
//   console.log("new pathname", location.pathname);
//   console.log("old pathname", prevLocation ? prevLocation.pathname : null);
// };
