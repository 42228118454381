import saveAs from "file-saver";
import JSZip from "jszip";
import { IPost } from "../interfaces";
import { getAllPosts, updateLeftDownload } from "../services";
import { weddingNames } from "./customs";

export const downloadImage = async (url: string, filename: string) => {
  await fetch(url)
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, filename));
};

export const downloadAllImages = async (
  setProgress: React.Dispatch<React.SetStateAction<number>>
) => {
  const zip = new JSZip();
  let progress = 0;
  const posts: ReadonlyArray<IPost> = await getAllPosts();

  const folder = zip.folder(`Photos de mariage ${weddingNames}`);
  await Promise.all(
    posts.map(async (post) => {
      const url = post.url;
      const filename = post.imageName;
      const response = await fetch(url);
      const blob = await response.blob();
      folder?.file(filename!, blob!, { base64: true });
      setProgress(((progress += 1) * 100) / posts.length / 1.3);
    })
  ).then(async () => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress < 90 ? prevProgress + 3 : prevProgress
      );
    }, 500);
    const content = await zip.generateAsync({ type: "blob" });
    clearInterval(timer);
    saveAs(content, "Photos_Mariage.zip");
    updateLeftDownload();
  });
};
