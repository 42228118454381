import { firestore } from "../firebaseConfig";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { IPost } from "../../../interfaces";

export const snapshotPosts = async (
  setPosts: React.Dispatch<React.SetStateAction<ReadonlyArray<IPost>>>
) => {
  const postsCol = collection(firestore, "Posts");
  const postsQuery = query(postsCol, orderBy("date"));
  onSnapshot(postsQuery, (snap) => {
    snap.docChanges().forEach((change) => {
      if (change.type === "added") {
        setPosts((posts) => [
          ...posts,
          { ...change.doc.data(), id: change.doc.id } as IPost,
        ]);
      } else if (change.type === "removed") {
        setPosts((posts) =>
          [...posts].filter((post) => post.id !== change.doc.id)
        );
      }
    });
  });
};
