import { firestore, storage } from "../firebaseConfig";
import imageCompression from "browser-image-compression";

import {
  addDoc,
  collection,
  getDocs,
  doc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  deleteDoc,
  query,
  limit,
  orderBy,
  startAfter,
  QueryDocumentSnapshot,
  DocumentData,
  Query,
  endAt,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import {
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { IPost } from "../../../interfaces";
import moment from "moment";

const postToFirestore = (post: IPost) => {
  return {
    ...post,
    date: Timestamp.fromDate(moment(post.date, "DD/MM/YYYY HH:mm:ss").toDate()),
  };
};

const postFromFirestore = (snapshot: any) => {
  const data = snapshot.data();
  return {
    ...data,
    id: snapshot.id,
    date: moment(data.date.toDate()).format("DD/MM/YYYY HH:mm:ss"),
  };
};

const addPost = async (
  url: string,
  compressedUrl: string,
  imageName: string,
  name: string
) => {
  const post: IPost = {
    url: url,
    compressedUrl: compressedUrl,
    imageName: imageName,
    date: moment().format("DD/MM/YYYY HH:mm:ss"),
    likes: [],
    likesNumber: 0,
    comments: [],
    author: { name },
  };
  const postsRef = collection(firestore, "Posts");
  await addDoc(postsRef, postToFirestore(post));
};

export const postImage = async (file: File, name: string) => {
  const fileName = file.name.split(".")[0];
  const fileExtension = file.name.split(".")[1];
  const pathReference = ref(
    storage,
    "posts/" + fileName + "_" + Date.now() + "." + fileExtension
  );
  const pathCompressedReference = ref(
    storage,
    "posts/" + fileName + "_compressed_" + Date.now() + "." + fileExtension
  );
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  await Promise.all([
    // GIFs compression is not supported so we don't compress them
    fileExtension !== "gif" && fileExtension !== "GIF"
      ? imageCompression(file, options).then(async (compressedFile) => {
          return await uploadBytes(
            pathCompressedReference,
            compressedFile
          ).then(async (snapshot) => {
            return await getDownloadURL(snapshot.ref);
          });
        })
      : null,
    uploadBytes(pathReference, file).then(async (snapshot) => {
      const url = await getDownloadURL(snapshot.ref);
      const imageName = snapshot.ref.name;
      return { url, imageName };
    }),
  ]).then(async (values) => {
    await addPost(
      values[1].url,
      values[0] ?? values[1].url,
      values[1].imageName,
      name
    );
  });
};

export const getAllPosts = async () => {
  const postsCol = collection(firestore, "Posts");
  const postsSnapshot = await getDocs(postsCol);
  const posts: IPost[] = [];

  postsSnapshot.forEach((doc) => {
    posts.push(postFromFirestore(doc));
  });

  return posts;
};

const getQueryRes = async (query: Query<DocumentData, DocumentData>) => {
  const postsSnapshot = await getDocs(query);
  const lastVisiblePost = postsSnapshot.docs[postsSnapshot.docs.length - 1];
  const posts: IPost[] = [];

  postsSnapshot.forEach((doc) => {
    posts.push(postFromFirestore(doc));
  });

  return { posts, lastVisiblePost };
};

export const getPost = async (id: string) => {
  const postsCol = collection(firestore, "Posts");
  const docSnap = await getDoc(doc(postsCol, id));
  return postFromFirestore(docSnap);
};

export const getPosts = async (sort: string) => {
  const postsCol = collection(firestore, "Posts");
  const postsQuery =
    sort === "dateDesc"
      ? query(postsCol, orderBy("date", "desc"), limit(8))
      : query(postsCol, orderBy("likesNumber", "desc"), limit(12));

  return await getQueryRes(postsQuery);
};

export const getPostsNextBatch = async (
  key: QueryDocumentSnapshot<DocumentData, DocumentData>,
  sort: string
) => {
  const postsCol = collection(firestore, "Posts");
  const postsQuery =
    sort === "dateDesc"
      ? query(postsCol, orderBy("date", "desc"), startAfter(key), limit(8))
      : query(
          postsCol,
          orderBy("likesNumber", "desc"),
          startAfter(key),
          limit(12)
        );

  return await getQueryRes(postsQuery);
};

export const getPostsUntilKey = async (
  key: QueryDocumentSnapshot<DocumentData, DocumentData>,
  sort: string
) => {
  const postsCol = collection(firestore, "Posts");
  const postsQuery =
    sort === "dateDesc"
      ? query(postsCol, orderBy("date", "desc"), endAt(key))
      : query(postsCol, orderBy("likesNumber", "desc"), endAt(key));

  return await getQueryRes(postsQuery);
};

export const getUserPosts = async (name: string) => {
  const postsCol = collection(firestore, "Posts");
  const postsSnapshot = await getDocs(postsCol);
  const posts: IPost[] = [];

  postsSnapshot.forEach((doc) => {
    if (doc.data().author.name === name) {
      posts.push(postFromFirestore(doc));
    }
  });

  posts.sort((a: IPost, b: IPost) => {
    return moment(a.date, "DD/MM/YYYY HH:mm:ss").diff(
      moment(b.date, "DD/MM/YYYY HH:mm:ss")
    );
  });
  return posts;
};

export const deletePost = async (postId: string, imageName: string) => {
  const postRef = doc(firestore, "Posts", postId);
  await deleteDoc(postRef);
  const imageRef = ref(storage, "posts/" + imageName);
  await deleteObject(imageRef);
};

export const likePost = async (
  postId: string,
  oldLikesNumber: number,
  action: string,
  name: string
) => {
  const likesRef = doc(firestore, "Posts", postId);
  await updateDoc(likesRef, {
    likes: action === "add" ? arrayUnion(name) : arrayRemove(name),
    likesNumber: action === "add" ? oldLikesNumber + 1 : oldLikesNumber - 1,
  });
};

// export const updatePostsName = async (oldName: string, newName: string) => {
//     const postsCol = collection(firestore, "Posts");
//     const postsSnapshot = await getDocs(postsCol);

//     postsSnapshot.forEach(async (document) => {
//       if (document.data().author.name === oldName) {
//         const postRef = doc(firestore, "Posts", document.id);
//         await updateDoc(postRef, {
//           ...document.data(),
//           author: { name: newName },
//         });
//       }
//       if (document.data().likes.includes(oldName)) {
//         const postRef = doc(firestore, "Posts", document.id);
//         await updateDoc(postRef, {
//           ...document.data(),
//           likes: arrayRemove(oldName),
//         });
//         await updateDoc(postRef, {
//           ...document.data(),
//           likes: arrayUnion(newName),
//         });
//       }
//     });
//   };

// export const updatePosts = async () => {
//   const postsCol = collection(firestore, "Posts");
//   const postsSnapshot = await getDocs(postsCol);

//   postsSnapshot.forEach(async (document) => {
//     const postRef = doc(firestore, "Posts", document.id);
//     await updateDoc(postRef, {
//       ...document.data(),
//       date: Timestamp.fromDate(
//         moment(document.data().date, "DD/MM/YYYY HH:mm:ss").toDate()
//       ),
//     });
//   });
// };
