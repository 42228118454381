import { IPost } from "../../interfaces";

export const handleLikeLocal = (
  post: IPost,
  setPosts: React.Dispatch<React.SetStateAction<ReadonlyArray<IPost>>>,
  setCurrentPost: React.Dispatch<React.SetStateAction<IPost | undefined>>,
  userName: string,
  like: boolean
) => {
  let newPost: IPost;
  if (like) {
    newPost = {
      ...post,
      likes: post.likes.filter((name) => name !== userName),
      likesNumber: post.likesNumber - 1,
    };
  } else {
    newPost = {
      ...post,
      likes: [...post.likes, userName],
      likesNumber: post.likesNumber + 1,
    };
  }

  setCurrentPost(newPost);
  setPosts((posts) => {
    const newPosts = [...posts];
    newPosts[posts.findIndex((post) => post.id === newPost.id)] = newPost;
    return newPosts;
  });
};

export const handleDeleteLocal = (
  post: IPost,
  setPosts: React.Dispatch<React.SetStateAction<ReadonlyArray<IPost>>>,
  setList?: React.Dispatch<React.SetStateAction<ReadonlyArray<IPost>>>
) => {
  setPosts((posts) => posts.filter((p) => p.id !== post.id));
  setList && setList((posts) => posts.filter((p) => p.id !== post.id));
};
