import { firestore } from "../firebaseConfig";
import { weddingNames } from "../../../utils";
import { collection, doc, updateDoc, getDoc } from "firebase/firestore";

export const getLeftDownload = async () => {
  const WedCol = collection(firestore, "WedStream");
  const docSnap = await getDoc(doc(WedCol, `Wedding ${weddingNames}`));
  return docSnap.data()?.leftDownload;
  // const postsCol = collection(
  //   firestore,
  //   `WedStream/Wedding ${weddingNames}/Posts`
  // );

  // const postsSnapshot = await getDocs(postsCol);
  // console.log(postsSnapshot.docs.length, postsSnapshot.docs[0].data());
};

export const updateLeftDownload = async () => {
  const WedCol = collection(firestore, "WedStream");
  const docSnap = await getDoc(doc(WedCol, `Wedding ${weddingNames}`));
  await updateDoc(doc(WedCol, `Wedding ${weddingNames}`), {
    leftDownload: docSnap.data()?.leftDownload - 1,
  });
};
