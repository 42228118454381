// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBitMEYSD9kdETv6S_bklLRfFH2Y8ZXmhk",
  authDomain: "wedstream-back.firebaseapp.com",
  projectId: "wedstream-back",
  storageBucket: "wedstream-back.appspot.com/",
  messagingSenderId: "703566241059",
  appId: "1:703566241059:web:b8e8e257eebc4434008096",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Storage and get a reference to the service
export const firestore = getFirestore(app);
export const storage = getStorage(app, "gs://wedstream-back.appspot.com");
