import { firestore } from "../firebaseConfig";
import { collection, onSnapshot, addDoc, where, query, getCountFromServer } from "firebase/firestore";
import { weddingNames } from "../../../utils";

export const snapshotEmojis = async (
  setEmojis: React.Dispatch<React.SetStateAction<ReadonlyArray<string>>>
) => {
  let initialState = true;
  const emojiCol = collection(
    firestore,
    `WedStream/Wedding ${weddingNames}/Emojis`
  );

  onSnapshot(emojiCol, (snap) => {
    if (initialState) initialState = false;
    else {
      snap.docChanges().forEach((change) => {
        if (change.type === "added") {
          setEmojis((emojis) => [...emojis, change.doc.data().emoji]);
        }
      });
    }
  });
};

export const addEmoji = async (emoji: string, userName: string) => {
  const emojiCol = collection(
    firestore,
    `WedStream/Wedding ${weddingNames}/Emojis`
  );
  await addDoc(emojiCol, { emoji, userName });
};

  export const getSentEmojisCount = async (userName:string) => {
    const emojiCol = collection(
      firestore,
      `WedStream/Wedding ${weddingNames}/Emojis`
    );
    const emojiQuery = query(emojiCol, where("userName", "==", userName), );
    const res = await getCountFromServer(emojiQuery);
    return res.data().count;
  }
