import { firestore } from "../firebaseConfig";
import { doc, updateDoc, arrayRemove, arrayUnion } from "firebase/firestore";
import { IComment } from "../../../interfaces";
import moment from "moment";
import { weddingNames } from "../../../utils";

export const addComment = async (
  postId: string,
  content: string,
  name: string
) => {
  const commentsRef = doc(firestore, "Posts", postId);
  await updateDoc(commentsRef, {
    comments: arrayUnion({
      content,
      name,
      date: moment().format("DD/MM/YYYY HH:mm:ss"),
    }),
  });
};

export const deleteComment = async (postId: string, comment: IComment) => {
  const commentsRef = doc(firestore, "Posts", postId);
  await updateDoc(commentsRef, {
    comments: arrayRemove(comment),
  });
};

export const updateComment = async (
  postId: string,
  oldComment: IComment,
  newComment: IComment
) => {
  const commentsRef = doc(firestore, "Posts", postId);
  await updateDoc(commentsRef, {
    comments: arrayRemove(oldComment),
  });
  await updateDoc(commentsRef, {
    comments: arrayUnion({
      content: newComment.content,
      name: newComment.name,
      date: moment().format("DD/MM/YYYY HH:mm:ss"),
    }),
  });
};
