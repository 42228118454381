import moment from "moment";

export const formatName = (name: string, firstName: string) => {
  const formattedName = name[0].toUpperCase() + name.slice(1).toLowerCase();
  const formattedFirstName =
    firstName[0].toUpperCase() + firstName.slice(1).toLowerCase();
  return formattedFirstName.trim() + " " + formattedName.trim();
};

export const formatDate = (date: string) => {
  const postDate = moment(date, "DD/MM/YYYY HH:mm:ss");
  return moment().diff(moment(postDate).startOf("day"), "days") > 0
    ? moment(postDate).format("DD/MM/YYYY HH:mm")
    : moment(postDate).format("HH:mm");
};
